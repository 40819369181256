$(document).ready(function () {
    $('.left-apparition').each(function( index ) {
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
            $(this).addClass('on');
        }
    });
    $('.right-apparition').each(function( index ) {
        var top_of_element = $(this).offset().top;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
            $(this).addClass('on');
        }
    });
    $('.top-apparition').each(function( index ) {
        var top_of_element = $(this).offset().top + 500;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
            $(this).addClass('on');
        }
    });
    $('.bottom-apparition').each(function( index ) {
        var top_of_element = $(this).offset().top - 1000;
        var bottom_of_element = $(this).offset().top + $(this).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
            $(this).addClass('on');
        }
    });
    $(document).scroll(function () {
        $('.left-apparition').each(function( index ) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
            }
        });
        $('.right-apparition').each(function( index ) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
            }
        });
        $('.top-apparition').each(function( index ) {
            var top_of_element = $(this).offset().top + 200; /* + 200 car on est placé en haut */
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
            }
        });
        $('.bottom-apparition').each(function( index ) {
            var top_of_element = $(this).offset().top - 200; /* - 200 car on est placé en bas */
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
                $(this).addClass('on');
            }
        });
    });
});